import React from "react";
import { Link } from "gatsby";
import classNames from "classnames/bind";
import styles from "./BigTag.module.scss";

const cx = classNames.bind(styles);

const BigTag = ({ children, to, color }) => (
  <Link to={to} className={cx("bigTag")}>
    {children}
    <div className={cx("bigTagUnderline")} style={{ backgroundColor: color }} />
  </Link>
);

export default BigTag;
