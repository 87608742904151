import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import BigTagList from "../../components/PageCode/BigTagList";
import BigTitleButtonArea from "../../components/PageCode/BigTitleButtonArea";
import TutorialsArea from "../../components/PageCode/TutorialsArea";
import PostsArea from "../../components/PageCode/PostsArea";
import bigTags from "../../../content/bigTagList.json";

export const pageQuery = graphql`
  query CodeQuery {
    allTutorials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/code/tutorials//" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 16
    ) {
      edges {
        node {
          excerpt(pruneLength: 50)
          fields {
            slug
          }
          frontmatter {
            title
            tutorial
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
            date(locale: "ko", formatString: "YYYY년 MM월 DD일")
            tags
            author
          }
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/code/posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 16
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 80)
          fields {
            slug
          }
          frontmatter {
            title
            tutorial
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
            date(locale: "ko", formatString: "YYYY년 MM월 DD일")
            tags
            author
          }
        }
      }
    }
  }
`;
const pageName = "code";

const CodePage = ({ location, data }) => {
  return (
    <Layout location={location} bgColor="#F3F2F2">
      <SEO title="Code" />

      <main role="main" style={{ marginTop: "56px" }}>
        <BigTagList list={bigTags[pageName]} menu={pageName} />
        <BigTitleButtonArea menu={pageName} />
        <TutorialsArea
          linkTo={`/${pageName}/tutorials`}
          tutorials={data.allTutorials.edges}
        />
        <PostsArea
          title={"Programming Posts"}
          linkTo={`/${pageName}/posts`}
          posts={data.allPosts.edges}
        />
      </main>
    </Layout>
  );
};

export default CodePage;
