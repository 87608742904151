import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./TutorialsArea.module.scss";
import classNames from "classnames/bind";
import TutorialCard from "../Common/TutorialCard";
import MainTitle from "../Common/MainTitle";

const cx = classNames.bind(styles);

const TutorialsArea = ({ linkTo, tutorials }) => {
  return (
    <div className={cx("tutorialArea")}>
      <Container className={cx("cont")}>
        {linkTo && <MainTitle title="Tutorials" linkTo={linkTo} />}

        <Row>
          {tutorials.map(({ node }) => {
            const key = node.fields.slug;
            return (
              <Col key={key} xl={3} lg={4} md={6} className="mb-4">
                <TutorialCard node={node} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default TutorialsArea;
