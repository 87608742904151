import React from "react";
import styles from "./BigTitleButtonArea.module.scss";
import classNames from "classnames/bind";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

const cx = classNames.bind(styles);

const BigTitleButtonArea = ({ menu }) => {
  return (
    <Container>
      <Row>
        <Col className={cx("bigTitleColumn")}>
          <Link to={`/${menu}/tutorials`} className={cx("bigBtnLink")}>
            <Button size="lg" className={cx("bigBtnInner")}>
              Tutorials
            </Button>
          </Link>
          <Link to={`/${menu}/posts`} className={cx("bigBtnLink")}>
            <Button size="lg" className={cx("bigBtnInner")}>
              Posts
            </Button>
          </Link>
          <Link to={`/${menu}/tags`} className={cx("bigBtnLink")}>
            <Button size="lg" className={cx("bigBtnInner")}>
              Tags
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default BigTitleButtonArea;
