import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./PostsArea.module.scss";
import classNames from "classnames/bind";
import MainTitle from "../Common/MainTitle";
import PostCard from "../Common/PostCard";

const cx = classNames.bind(styles);

const PostsArea = ({ title, linkTo, posts }) => {
  return (
    <div className={cx("postsArea")}>
      <Container className={cx("cont")}>
        <MainTitle title={title} linkTo={linkTo} />

        <Row>
          {posts.map(({ node }) => {
            return (
              <Col
                key={node.fields.slug}
                lg={6}
                style={{ marginBottom: "20px" }}
              >
                <PostCard node={node} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default PostsArea;
