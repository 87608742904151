import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import classNames from "classnames/bind";
import styles from "./BigTagList.module.scss";
import BigTag from "../Common/BigTag";

const cx = classNames.bind(styles);

const BigTagList = ({ list, menu }) => {
  return (
    <div className={cx("bigTagListWrapper")}>
      <Container>
        <Row>
          <Col className={cx("bigTagColumn")}>
            {list.map(({ name, url, color }) => {
              return (
                <BigTag
                  key={url}
                  to={`/${menu}/tags/${url.toLowerCase()}`}
                  color={color}
                >
                  {name}
                </BigTag>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BigTagList;
