import React from "react";
import styles from "./PostCard.module.scss";
import classNames from "classnames/bind";
import { Card } from "react-bootstrap";
import { Link } from "gatsby";

const cx = classNames.bind(styles);

const PostCard = ({ node }) => {
  const excerpt = !node.frontmatter.image
    ? node.excerpt
    : node.excerpt.slice(0, 50) + "...";

  return (
    <Card className={cx("card")}>
      <Link
        to={node.fields.slug}
        className={cx({ imgLink: !!node.frontmatter.image })}
      >
        {node.frontmatter.image && (
          <img
            src={node.frontmatter.image.childImageSharp.fluid.src}
            alt={node.frontmatter.title}
            width={100}
            height={100}
            className={cx("cardImg")}
          />
        )}
      </Link>

      <Link to={node.fields.slug} className={cx("cardBodyLink")}>
        <Card.Body className={cx("cardBody")}>
          <Card.Title className={cx("cardTitle")}>
            {node.frontmatter.title}
          </Card.Title>
          <Card.Text className={cx("cardMeta")}>
            <small>{node.frontmatter.date}</small>
          </Card.Text>
          <Card.Text
            className={cx("cardDesc")}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </Card.Body>
      </Link>
    </Card>
  );
};

export default PostCard;
